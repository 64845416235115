import React, { FunctionComponent } from 'react';
import Svg from './Svg';
import { useTheme } from '../hooks';

interface Props {
  color?: string;
  size?: string;
}

const Check: FunctionComponent<Props> = ({
  color,
  size = '24px',
  ...rest
}: Props) => {
  const theme = useTheme();
  const fillColor: string = color || theme.colors.black;
  return (
    <Svg
      color={fillColor}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
    </Svg>
  );
};

export default Check;
