import React, { FunctionComponent } from 'react';
import ChevronDown, { ChevronDownProps } from './ChevronDown';
import ChevronUp from './ChevronUp';

type Props = {
  isOpen: boolean;
} & ChevronDownProps;

const Chevron: FunctionComponent<Props> = ({ isOpen, ...rest }: Props) =>
  isOpen ? <ChevronDown {...rest} /> : <ChevronUp {...rest} />;

export default Chevron;
