import React, { FunctionComponent } from 'react';
import Svg, { SvgProps } from './Svg';
import { useTheme } from '../hooks';

type ChevronUpProps = {
  color?: string;
  size?: number;
} & SvgProps;

const ChevronUp: FunctionComponent<ChevronUpProps> = ({
  color,
  size = 12,
  ...rest
}: ChevronUpProps) => {
  const theme = useTheme();
  const fillColor: string =
    typeof color !== 'undefined' ? theme.colors[color] : theme.colors.black;

  return (
    <Svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <title>Icons/Arrow Up</title>
      <defs>
        <polygon
          id="path-1"
          points="15.497789 15 17 13.6138152 12 9 7 13.6138152 8.50221105 15 12 11.7723697"
        ></polygon>
      </defs>
      <g
        id="Simplified-registration"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Simplified-Reg-V3-Responsive"
          transform="translate(-261.000000, -343.000000)"
        >
          <g
            id="Icons/Arrow-Up"
            transform="translate(266.000000, 346.000000) rotate(-180.000000) translate(-266.000000, -346.000000) translate(254.000000, 334.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use
              id="Arrow-down"
              fill={fillColor}
              fillRule="evenodd"
              transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
              xlinkHref="#path-1"
            ></use>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default ChevronUp;
