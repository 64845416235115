import React, { FunctionComponent } from 'react';
import { useTheme } from '../hooks';
import withStyled from '../../utils/withStyled';

interface Props {
  size?: number;
  color?: string;
}

const Warning: FunctionComponent<Props> = ({ size, color, ...rest }: Props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      width={`${size || 24}px`}
      height={`${size || 24}px`}
      viewBox="0 0 1000 1000"
      {...rest}
    >
      <g>
        <path
          d="M868,953.8H132.1c-49.3,0-89-19.3-108.8-53.1C3.5,867,5.9,822.9,29.9,779.8l371.3-665.4c24.2-43.4,60.1-68.2,98.6-68.2c38.5,0,74.4,24.8,98.6,68.2L970,779.9c24,43.1,26.5,87.1,6.7,120.8C956.9,934.5,917.3,953.8,868,953.8L868,953.8z M499.8,116.1c-12.2,0-26.2,12.1-37.6,32.4L90.9,813.9c-11.6,20.9-14.4,39.6-7.4,51.5c7,11.8,24.7,18.6,48.5,18.6H868c23.9,0,41.6-6.7,48.5-18.6c6.9-11.8,4.2-30.6-7.4-51.4L537.4,148.5C526.1,128.2,512,116.1,499.8,116.1L499.8,116.1z M500,638.7c-19.3,0-34.9-15.6-34.9-34.9V289.5c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9v314.3C534.9,623.1,519.3,638.7,500,638.7L500,638.7z M447.6,761.1c0-13.7,5.6-27.3,15.3-37.1c9.7-9.7,23.3-15.3,37-15.3c13.7,0,27.3,5.6,37,15.3c9.7,9.7,15.3,23.3,15.3,37.1 M552.4,755.1c0,13.7-5.6,27.3-15.3,37c-9.7,9.7-23.3,15.3-37,15.3c-13.7,0-27.3-5.6-37-15.3c-9.7-9.7-15.3-23.3-15.3-37"
          fill={color || useTheme().colors.black}
        />
      </g>
    </svg>
  );
};

export default withStyled(Warning);
