import React, { FunctionComponent } from 'react';
import Svg from './Svg';
import { useTheme } from '../hooks';

export interface HideProps {
  color?: string;
}

const Hide: FunctionComponent<HideProps> = ({ color, ...rest }: HideProps) => {
  const theme = useTheme();
  return (
    <Svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <g
        id="Icons/hide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M11.8398,9.0195 L12.0098,9.0095 C13.6698,9.0095 15.0098,10.3495 15.0098,12.0095 L14.9898,12.1695 L11.8398,9.0195 Z M1.9998,4.2695 L3.2698,2.9995 L20.9998,20.7305 L19.7308,21.9995 L16.7998,19.0805 L16.3798,18.6605 C15.0298,19.2005 13.5498,19.4995 11.9998,19.4995 C6.9998,19.4995 2.7308,16.3895 0.9998,11.9995 C1.7768,10.0315 3.0688,8.3075 4.7398,7.0095 L4.2798,6.5495 L1.9998,4.2695 Z M7.5298,9.7995 C7.1998,10.4695 6.9998,11.2095 6.9998,11.9995 C6.9998,14.7595 9.2398,16.9995 11.9998,16.9995 C12.7898,16.9995 13.5298,16.7995 14.1998,16.4695 L12.6508,14.9195 C12.4408,14.9695 12.2198,14.9995 11.9998,14.9995 C10.3398,14.9995 8.9998,13.6605 8.9998,11.9995 C8.9998,11.7805 9.0298,11.5595 9.0798,11.3495 L7.5298,9.7995 Z M11.9998,6.9995 C11.3498,6.9995 10.7398,7.1295 10.1698,7.3605 L8.0098,5.2005 C9.2498,4.7495 10.5898,4.4995 11.9898,4.4995 C16.9898,4.4995 21.2598,7.6105 22.9898,11.9995 C22.2598,13.8605 21.0698,15.4905 19.5598,16.7495 L16.6398,13.8305 C16.8698,13.2595 16.9998,12.6505 16.9998,11.9995 C16.9998,9.2405 14.7598,6.9995 11.9998,6.9995 Z"
          id="eye-hide"
          fill={color || theme.colors.gray}
        />
      </g>
    </Svg>
  );
};

export default Hide;
