import React, { FunctionComponent } from 'react';
import Svg, { SvgProps } from './Svg';
import { useTheme } from '../hooks';

type Props = {
  color?: string;
} & SvgProps;

const MdliveLogo: FunctionComponent<Props> = ({ color, ...rest }: Props) => {
  const theme = useTheme();
  const fill =
    typeof color !== 'undefined' ? theme.colors[color] || color : undefined;

  return (
    <Svg
      width="109px"
      height="19px"
      viewBox="0 0 109 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <title>MDLIVE logo</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="AND-Dashboard" transform="translate(-126.000000, -42.000000)">
          <g id="Header">
            <g transform="translate(0.000000, 22.000000)" id="MDLIVE-logo">
              <g transform="translate(126.000000, 20.000000)">
                <path
                  d="M107.593426,1.43771626 C107.764706,1.43771626 107.858131,1.34429066 107.858131,1.21453287 L107.858131,1.21453287 C107.858131,1.06401384 107.754325,0.98615917 107.583045,0.98615917 L107.245675,0.98615917 L107.245675,1.44290657 L107.593426,1.43771626 Z M106.903114,0.679930796 L107.593426,0.679930796 C107.77092,0.669021979 107.945204,0.730864595 108.076125,0.851211073 C108.162897,0.941698995 108.209655,1.06326851 108.205882,1.18858131 L108.205882,1.18858131 C108.215973,1.40739074 108.077464,1.60556469 107.868512,1.67128028 L108.247405,2.2266436 L107.847751,2.2266436 L107.515571,1.70761246 L107.245675,1.70761246 L107.245675,2.2266436 L106.903114,2.2266436 L106.903114,0.679930796 Z M108.82872,1.5 L108.82872,1.5 C108.82872,0.767601625 108.234994,0.173875433 107.502595,0.173875433 C106.770197,0.173875433 106.176471,0.767601625 106.176471,1.5 L106.176471,1.5 C106.176471,2.23239837 106.770197,2.82612457 107.502595,2.82612457 C108.234994,2.82612457 108.82872,2.23239837 108.82872,1.5 L108.82872,1.5 Z M106,1.5 L106,1.5 C106,0.671572875 106.671573,0 107.5,0 C108.328427,0 109,0.671572875 109,1.5 L109,1.5 C109,2.32842712 108.328427,3 107.5,3 C106.671573,3 106,2.32842712 106,1.5 L106,1.5 Z"
                  id="Shape"
                  fill={fill || '#8E8F92'}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M0,0 L3.23585434,0 C4.01955486,-0.0046843369 4.74931519,0.398384389 5.16246499,1.06416649 L9.5,8.73118995 L13.837535,1.06416649 C14.2506848,0.398384389 14.9804451,-0.0046843369 15.7641457,0 L19,0 L19,18.9946794 L14.8487395,18.9946794 L14.8487395,6.97006242 L10.7453782,13.7857856 L8.21204482,13.7857856 L4.1512605,6.9647418 L4.1512605,19 L0,19 L0,0 Z"
                  id="Path"
                  fill={fill || '#77787B'}
                ></path>
                <path
                  d="M31.1913149,15.2319328 C34.5314303,15.2319328 36.7461282,12.9221289 36.7461282,9.57983193 L36.7461282,9.52661064 C36.7461282,6.16302521 34.505618,3.8 31.1913149,3.8 L28.0576981,3.8 L28.0576981,15.2319328 L31.1913149,15.2319328 Z M24,1.99047619 C24,0.891166546 24.8644317,0 25.9307622,0 L31.1913149,0 C36.9836016,0 41,4.09803922 41,9.44677871 L41,9.5 C41,14.8221289 36.999089,19 31.1913149,19 L24,19 L24,1.99047619 Z"
                  id="Shape"
                  fill={fill || '#77787B'}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M44,0 L46.2487329,0 C47.3731115,0 48.2846004,0.888783747 48.2846004,1.98515406 L48.2846004,15.2 L55.9641326,15.2 C56.5040782,15.2 57.021909,15.4091496 57.4037082,15.7814382 C57.7855074,16.1537268 58,16.6586585 58,17.1851541 L58,19 L44,19 L44,0 Z"
                  id="Path"
                  fill={fill || '#F16723'}
                ></path>
                <path
                  d="M63.9006369,0 L66,0 L66,19 L62,19 L62,1.96918768 C62.0111474,0.880271247 62.8580247,0.00284946325 63.9006369,0"
                  id="Path"
                  fill={fill || '#F16723'}
                ></path>
                <path
                  d="M68,0 L71.043818,0 C71.9876489,-0.00972100737 72.8362041,0.570902117 73.1649456,1.45150273 L77.5715881,13.2683705 L81.9570193,1.46205822 C82.2861296,0.580302053 83.133054,-0.00341978974 84.0781468,0 L87,0 L79.3374267,19 L75.6572704,19 L68,0 Z"
                  id="Path"
                  fill={fill || '#F16723'}
                ></path>
                <path
                  d="M91.983871,0 L103.860215,0 L103.860215,3.72132065 L94.1827957,3.72132065 L94.1827957,7.57022943 L103.231183,7.57022943 L103.231183,11.2915501 L94.1935484,11.2915501 L94.1935484,15.2786794 L104,15.2786794 L104,17.0117515 C104,18.1098308 103.099755,19 101.989247,19 L90,19 L90,1.96698377 C90,0.880648631 90.8906164,0 91.9892473,0"
                  id="Path"
                  fill={fill || '#F16723'}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default MdliveLogo;
