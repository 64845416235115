import React, { FunctionComponent } from 'react';
import Svg from './Svg';

export interface ShowProps {
  color?: string;
  size?: string;
}

const Hamburger: FunctionComponent<ShowProps> = ({
  color,
  size = '24px',
  ...rest
}: ShowProps) => {
  // const theme = useTheme();

  return (
    <Svg
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      color={color}
      {...rest}
    >
      <title>Hamburger Menu</title>
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path d="M1163.3,4998.5c-139.9-40.3-318.2-143.8-427.5-251.1c-534.8-527.2-306.7-1430.1,419.8-1652.4c109.3-32.6,279.9-34.5,3843.5-34.5c3563.7,0,3734.3,1.9,3843.5,34.5c544.4,166.8,843.5,741.9,661.3,1271c-90.1,258.8-251.1,439-509.9,567.4l-166.8,82.4l-3787.9,3.8C2025.9,5021.5,1234.2,5017.6,1163.3,4998.5z" />
          <path d="M1224.6,1093.6c-161-30.7-360.4-141.9-490.8-272.2C400.3,487.8,346.6-10.6,599.7-409.3C730-614.4,983.1-788.9,1218.8-838.7c143.8-30.7,7416.8-30.7,7560.6,0c360.4,74.8,665.2,379.6,753.4,747.6c113.1,481.2-149.5,956.6-632.6,1150.2l-115,46L5047,1107C2963.3,1108.9,1270.6,1103.2,1224.6,1093.6z" />
          <path d="M1203.5-2828.5c-178.3-34.5-427.5-193.6-552.1-354.6c-423.7-550.2-176.4-1347.6,483.1-1554.7l136.1-42.2h3728.5h3728.5l136.1,42.2c264.6,84.3,469.7,262.6,592.4,515.6c251.1,513.8,13.4,1123.4-527.2,1353.4l-105.4,44.1l-3786,1.9C2955.6-2820.8,1230.3-2824.7,1203.5-2828.5z" />
        </g>
      </g>
    </Svg>
  );
};

export default Hamburger;
